





































































































































































































































































import { setNumbering } from "@/helpers/common";
import { debounceProcess } from "@/helpers/debounce";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { DataListMasterAsset } from "@/models/interface/assets.interface";
import {
  ContactData,
  ResponseListMaster,
} from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import {
  DataListInternalContract,
  DataListReportInternalContract,
} from "@/models/interface/salesOrder.interface";
import { assetsServices } from "@/services/assets.service";
import { contactServices } from "@/services/contact.service";
import { logisticServices } from "@/services/logistic.service";
import { masterServices } from "@/services/master.service";
import { salesOrderServices } from "@/services/salesorder.service";
import moment from "moment";
import printJs from "print-js";
import Vue from "vue";

export default Vue.extend({
  name: "ReportsInternalContract",
  data() {
    this.getListCustomer = debounceProcess(this.getListCustomer, 200);
    this.getListInternalContractNo = debounceProcess(
      this.getListInternalContractNo,
      200
    );
    this.getListBranch = debounceProcess(this.getListBranch, 200);
    this.getListStatus = debounceProcess(this.getListStatus, 200);
    return {
      DEFAULT_DATE_FORMAT,
      modelForm: {
        date: [] as any,
      },
      dataSource: [] as DataListReportInternalContract[],
      columnsTable: [
        {
          title: this.$t("lbl_number_short"),
          dataIndex: "no",
          key: "no",
          width: 90,
        },
        {
          title: this.$t("lbl_branch"),
          dataIndex: "branchName",
          key: "branchName",
          width: 150,
        },
        {
          title: this.$t("lbl_internal_contract_no"),
          dataIndex: "documentNo",
          key: "documentNo",
          width: 200,
        },
        {
          title: this.$t("lbl_date"),
          dataIndex: "contractDate",
          key: "contractDate",
          width: 150,
        },
        {
          title: this.$t("lbl_revised"),
          dataIndex: "revised",
          key: "revised",
          width: 150,
        },
        {
          title: this.$t("lbl_pic"),
          dataIndex: "picName",
          key: "picName",
          width: 150,
        },
        {
          title: this.$t("lbl_customer"),
          dataIndex: "customerName",
          key: "customerName",
          width: 350,
        },
        {
          title: this.$t("lbl_currency"),
          dataIndex: "currencyCode",
          key: "currencyCode",
          width: 150,
        },
        {
          title: this.$t("lbl_billing_period_months"),
          dataIndex: "billingPeriod",
          key: "billingPeriod",
          width: 250,
        },
        {
          title: this.$t("lbl_billing_type"),
          dataIndex: "billingType",
          key: "billingType",
          width: 150,
        },
        {
          title: this.$t("lbl_rent_starting_date"),
          dataIndex: "startRent",
          key: "startRent",
          width: 200,
        },
        {
          title: this.$t("lbl_rent_period_months"),
          dataIndex: "rentPeriod",
          key: "rentPeriod",
          width: 200,
        },
        {
          title: this.$t("lbl_sales"),
          dataIndex: "salesName",
          key: "salesName",
          width: 250,
        },
        {
          title: this.$t("lbl_salary"),
          dataIndex: "salary",
          key: "salary",
          width: 200,
        },
        {
          title: this.$t("lbl_total_rent"),
          dataIndex: "totalRentPrice",
          key: "totalRentPrice",
          width: 200,
        },
        {
          title: this.$t("lbl_status"),
          dataIndex: "status",
          key: "status",
          width: 200,
        },
      ],
      innerColumns: [
        {
          title: this.$t("lbl_unit_code"),
          dataIndex: "unitCode",
          key: "unitCode",
          width: 100,
        },
        {
          title: this.$t("lbl_equipment"),
          dataIndex: "equipment",
          key: "equipment",
          width: 100,
        },
        {
          title: this.$t("lbl_merk"),
          dataIndex: "merk",
          key: "merk",
          width: 100,
        },
        {
          title: this.$t("lbl_type"),
          dataIndex: "type",
          key: "type",
          width: 100,
        },
        {
          title: this.$t("lbl_qty"),
          key: "qty",
          width: 100,
          dataIndex: "qty",
          scopedSlots: { customRender: "qty" },
        },
        {
          title: this.$t("lbl_rent_price"),
          dataIndex: "rentPrice",
          key: "rentPrice",
          width: 100,
        },
      ],
      page: 1 as number,
      totalElements: 0 as number,
      limit: 10 as number,
      loading: {
        listCustomer: false,
        internalContractNo: false,
        branchName: false,
        status: false,
        revised: false,
        table: false,
        submit: false,
        download: false,
        print: false,
        unitCode: false,
      },
      params: {} as RequestQueryParamsModel,
      dataListCustomer: [] as ContactData[],
      dataListInternalContractNo: [] as DataListInternalContract[],
      dataListBranch: [] as DataWarehouseBranch[],
      dataListStatus: [] as ResponseListMaster[],
      dataUnitCode: [] as DataListMasterAsset[],
      dataListRevised: [
        {
          value: "yes",
          label: "Yes",
        },
        {
          value: "no",
          label: "No",
        },
      ],
      form: this.$form.createForm(this, { name: "listInternalContract" }),
      formRules: {
        customerName: {
          label: "lbl_customer_name",
          name: "customerName",
          placeholder: "lbl_customer_name_placeholder",
          decorator: [
            "customerName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        internalContractNo: {
          label: "Internal Contract No",
          name: "internalContractNo",
          placeholder: "Insert Internal Contract No",
          decorator: [
            "internalContractNo",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        internalContractDate: {
          label: "Internal Contract Date",
          name: "internalContractDate",
          placeholder: "Insert Internal Contract Date",
          decorator: [
            "internalContractDate",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        unitCode: {
          label: "lbl_unit_code",
          name: "unitCode",
          placeholder: "lbl_unit_code_placeholder",
          decorator: [
            "unitCode",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        branchName: {
          label: "Branch Name",
          name: "branchName",
          placeholder: "Insert Branch Name",
          decorator: [
            "branchName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        status: {
          label: "Status",
          name: "status",
          placeholder: "Insert Status",
          decorator: [
            "status",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        revised: {
          label: "Revised",
          name: "revised",
          placeholder: "Insert Revised",
          decorator: [
            "revised",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
    };
  },
  created() {
    this.getListCustomer("");
    this.getListInternalContractNo("");
    this.getListBranch("");
    this.getListStatus("");
    this.getUnitCode("");
  },
  methods: {
    moment,
    getUnitCode(valueSearch: string) {
      const params: RequestQueryParamsModel = new RequestQueryParams();
      if (valueSearch) params.search = `unitCode~*${valueSearch}*`;
      this.loading.unitCode = true;
      assetsServices
        .listMasterAsset(params)
        .then(response => {
          this.dataUnitCode = response.data;
        })
        .finally(() => (this.loading.unitCode = false));
    },
    getListCustomer(valueSearch) {
      const params = new RequestQueryParams();
      params.search = "customer~true_AND_active~true";
      if (valueSearch) {
        params.search += `_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*`;
      }
      this.loading.listCustomer = true;
      contactServices
        .listContactData(params)
        .then(response => (this.dataListCustomer = response.data))
        .finally(() => (this.loading.listCustomer = false));
    },
    getListInternalContractNo(valueSearch) {
      const params = new RequestQueryParams();
      if (valueSearch) params.search = `documentNo~*${valueSearch}*`;
      this.loading.internalContractNo = true;
      salesOrderServices
        .getListInternalContract(params)
        .then(response => (this.dataListInternalContractNo = response.data))
        .finally(() => (this.loading.internalContractNo = false));
    },
    getListBranch(valueSearch) {
      const params = new RequestQueryParams();
      if (valueSearch)
        params.search = `name~*${valueSearch}*_OR_address~*${valueSearch}*`;
      this.loading.branchName = true;
      logisticServices
        .listWarehouseBranch(params, "")
        .then(response => (this.dataListBranch = response.data))
        .finally(() => (this.loading.branchName = false));
    },
    getListStatus(valueSearch) {
      const params = new RequestQueryParams();
      params.name = "STATUS_INTERNAL_CONTRACT";
      if (valueSearch) params.search += `_AND_value~*${valueSearch}*`;
      this.loading.status = true;
      masterServices
        .listMaster(params)
        .then(response => (this.dataListStatus = response))
        .finally(() => (this.loading.status = false));
    },
    handleDownload() {
      this.form.validateFields((err, res) => {
        if (err) return;
        this.params.params = this.checkParams(res);
        this.loading.download = true;
        salesOrderServices
          .getReportDownloadInternalContract(this.params)
          .then(response => {
            if (response) {
              const url = window.URL.createObjectURL(new Blob([response]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "internal_contract.xlsx"); //or any other extension
              document.body.appendChild(link);
              link.click();
            }
          })
          .finally(() => (this.loading.download = false));
      });
    },
    handlePrint() {
      this.form.validateFields((err, res) => {
        if (err) return;
        this.params.params = this.checkParams(res);
        this.loading.print = true;
        salesOrderServices
          .getReportPrintInternalContract(this.params)
          .then(response => {
            if (response) {
              const url = window.URL.createObjectURL(new Blob([response]));
              printJs(url);
            }
          })
          .finally(() => (this.loading.print = false));
      });
    },
    onRangePickerChange(dates: Array<any>): void {
      if (!dates.length) {
        this.modelForm.date = undefined;
      }
    },
    responsePageSizeChange(_, pageSize: number) {
      this.limit = pageSize;
      this.page = 1;
      this.validateForm();
    },
    responseCurrentPageChange(current: number, size: number) {
      this.page = current;
      this.limit = size;
      this.validateForm();
    },
    handleCancel() {
      this.modelForm.date = [];
      this.dataSource = [];
      this.totalElements = 0;
      this.form.resetFields();
    },
    handleSelectType(value: string): void {
      this.form.setFieldsValue({
        adj: value,
      });
    },
    checkValue(value): string {
      if (value && !value.includes("undefined")) {
        return value;
      } else {
        return ",ALL";
      }
    },
    checkParams(res): string {
      let params = "";
      // company is mandatory
      params += this.$store.state.authStore.authData.companyName;
      params += this.checkValue(
        `,${this.dataListCustomer
          .find(dataFind => dataFind.id === res["customerName"])
          ?.fullName?.replaceAll(",", " ")}`
      );
      params += this.checkValue(`,${res["internalContractNo"]}`);
      // date from & date to
      params += this.checkValue(
        res["internalContractDate"]
          ? `,${moment(res["internalContractDate"][0]).format(
              DEFAULT_DATE_FORMAT
            )}`
          : null
      );
      params += this.checkValue(
        res["internalContractDate"]
          ? `,${moment(res["internalContractDate"][1]).format(
              DEFAULT_DATE_FORMAT
            )}`
          : null
      );
      params += this.checkValue(
        `,${
          this.dataListBranch.find(
            dataFind => dataFind.id === res["branchName"]
          )?.name
        }`
      );
      params += this.checkValue(`,${res["status"]}`);
      params += this.checkValue(`,${res["revised"]}`);
      params += this.checkValue(`,${res["unitCode"]}`);

      return params;
    },
    assignSearch(key, value, and): string {
      if (key === "customerName" && value)
        return and + `customer.secureId~${value}`;
      else if (key === "internalContractNo" && value)
        return and + `documentNo~${value}`;
      else if (key === "internalContractDate" && value)
        return (
          and +
          `contractDate>=${moment(value[0])
            .set({ hour: 0, minute: 0, second: 0 })
            .utcOffset("+07")
            .format()}_AND_contractDate<=${moment(value[1])
            .set({ hour: 23, minute: 59, second: 59 })
            .utcOffset("+07")
            .format()}`
        );
      else if (key === "branchName" && value)
        return and + `branch.secureId~${value}`;
      else if (key === "status" && value)
        return and + `status~${value.replace(" ", "_")}`;
      else if (key === "revised" && value === "yes") return and + `revised>0`;
      else if (key === "revised" && value === "no") return and + `revised~0`;
      else return "";
    },
    dynamicSearch(res): string {
      let search = "";
      Object.keys(res).forEach(key => {
        if (!search) {
          search = this.assignSearch(key, res[key], "");
        } else {
          search += this.assignSearch(key, res[key], "_AND_");
        }
      });
      return search;
    },
    validateForm(): void {
      this.form.validateFields((err, values) => {
        if (err) return;

        this.onSubmit(values);
      });
    },
    async onSubmit(formValues): Promise<void> {
      try {
        const params = new RequestQueryParams(
          "",
          this.page - 1,
          "createdDate:DESC",
          this.limit
        );
        if (formValues.unitCode) params.unitId = formValues.unitCode;
        params.search = this.dynamicSearch(formValues);
        this.loading.table = true;
        salesOrderServices
          .getListReportInternalContract(params)
          .then(data => {
            this.dataSource = data.data.map((dataMap, index) => {
              dataMap.no = setNumbering(params.page, params.limit, index);
              dataMap.key = dataMap.no;
              dataMap.lineDTO = dataMap.lineDTO.map((item, i) => ({
                ...item,
                key: i,
              }));
              return dataMap;
            });
            this.params = params;
            this.params.params = this.checkParams(formValues);
            this.totalElements = data.totalElements;
          })
          .finally(() => (this.loading.table = false));
      } catch (error) {
        this.showNotifError("notif_create_fail");
      }
    },
  },
});
