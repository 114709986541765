var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_internal_contract_report") } },
    [
      _c(
        "a-form",
        {
          attrs: {
            "wrapper-col": { span: 12 },
            "label-col": { span: 8 },
            "label-align": "left",
            form: _vm.form
          },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.validateForm.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.customerName.label) }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.customerName.decorator,
                              expression: "formRules.customerName.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.customerName.name,
                            placeholder: _vm.$t(
                              _vm.formRules.customerName.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.listCustomer,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListCustomer(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataListCustomer, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.fullName) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.fullName) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.internalContractNo.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.internalContractNo.decorator,
                              expression:
                                "formRules.internalContractNo.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.internalContractNo.name,
                            placeholder: _vm.$t(
                              _vm.formRules.internalContractNo.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.internalContractNo,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListInternalContractNo(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataListInternalContractNo, function(
                          data,
                          index
                        ) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.documentNo } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.documentNo) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.documentNo) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.internalContractDate.label)
                      }
                    },
                    [
                      _c("a-range-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.internalContractDate.decorator,
                            expression:
                              "formRules.internalContractDate.decorator"
                          }
                        ],
                        attrs: {
                          ranges: {
                            Today: [_vm.moment(), _vm.moment()],
                            "This Month": [
                              _vm.moment(),
                              _vm.moment().endOf("month")
                            ]
                          },
                          name: _vm.formRules.internalContractDate.name,
                          format: _vm.DEFAULT_DATE_FORMAT,
                          type: "date"
                        },
                        on: { change: _vm.onRangePickerChange }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.unitCode.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.unitCode.decorator,
                              expression: "formRules.unitCode.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.unitCode.name,
                            placeholder: _vm.$t(
                              _vm.formRules.unitCode.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.unitCode,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getUnitCode(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataUnitCode, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.unitCode) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.unitCode) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t(_vm.formRules.branchName.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.branchName.decorator,
                              expression: "formRules.branchName.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.branchName.name,
                            placeholder: _vm.$t(
                              _vm.formRules.branchName.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.branchName,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListBranch(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataListBranch, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s("" + data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s("" + data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t(_vm.formRules.status.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.status.decorator,
                              expression: "formRules.status.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.status.name,
                            placeholder: _vm.$t(
                              _vm.formRules.status.placeholder
                            ),
                            showSearch: true,
                            "option-filter-prop": "children",
                            "filter-option": false,
                            loading: _vm.loading.status,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListStatus(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataListStatus, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.value } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.value) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.value) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t(_vm.formRules.revised.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.revised.decorator,
                              expression: "formRules.revised.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.revised.name,
                            placeholder: _vm.$t(
                              _vm.formRules.revised.placeholder
                            ),
                            showSearch: false,
                            allowClear: true
                          }
                        },
                        _vm._l(_vm.dataListRevised, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.value } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.label) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.label) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleCancel } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            loading: _vm.loading.table,
                            "html-type": "submit",
                            type: "primary"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("a-table", {
                attrs: {
                  columns: _vm.columnsTable,
                  "data-source": _vm.dataSource,
                  loading: _vm.loading.table,
                  pagination: false,
                  scroll: { x: "calc(100% + 700px)", y: 700 }
                },
                scopedSlots: _vm._u([
                  {
                    key: "qty",
                    fn: function(text, record) {
                      return _c("span", {}, [
                        _vm._v(_vm._s(_vm._f("qty")(record.qty)))
                      ])
                    }
                  },
                  {
                    key: "expandedRowRender",
                    fn: function(record) {
                      return _c("a-table", {
                        attrs: {
                          columns: _vm.innerColumns,
                          scroll: { x: 1200 },
                          "data-source": record.lineDTO
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "qty",
                            fn: function(text2, record2) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(_vm._f("qty")(record2.qty)))
                              ])
                            }
                          }
                        ])
                      })
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("a-pagination", {
                attrs: {
                  "show-size-changer": "",
                  "page-size": _vm.limit,
                  total: _vm.totalElements
                },
                on: {
                  "update:pageSize": function($event) {
                    _vm.limit = $event
                  },
                  "update:page-size": function($event) {
                    _vm.limit = $event
                  },
                  showSizeChange: _vm.responsePageSizeChange,
                  change: _vm.responseCurrentPageChange
                },
                model: {
                  value: _vm.page,
                  callback: function($$v) {
                    _vm.page = $$v
                  },
                  expression: "page"
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12, align: "end" } },
            [
              _c(
                "a-tag",
                {
                  staticStyle: { "font-size": "13px" },
                  attrs: { color: "#8c8c8c" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("lbl_total_found")) +
                      " : " +
                      _vm._s(_vm.totalElements || 0) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.loading.print,
                        icon: "printer"
                      },
                      on: { click: _vm.handlePrint }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.loading.download,
                        icon: "download"
                      },
                      on: { click: _vm.handleDownload }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }